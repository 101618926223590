import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  withConfig,
  withModal,
  withRouter as withRouterActions
} from '@mulesoft/exchange-react-hooks';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import PublishErrorHint from '~/components/Hints/PublishErrorHint';
import AssetLimitModal from '~/components/Modals/AssetLimitModal';
import withFocusRestore from '~/components/WithFocusRestore';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as sessionActions } from '~/portals/store/domains/session';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import {
  getActiveOrganization,
  getActiveOrganizationId
} from '~/utils/organization';
import { PUBLISH_FORM_V2_ENABLED } from '~/domains/featureFlags/keys';
import * as objectsUtils from '~/utils/objects';
import CreateAsset from './CreateAsset';

const mapStateToProps = (state, { match: { params }, route }) => {
  const profile = commonSelectors.profile(state);
  const { assetsLimit } = profile.organization;
  const context = commonSelectors.context(state);
  const isPublishFormV2Enabled = featureFlagsSelectors.featureFlag(
    state,
    PUBLISH_FORM_V2_ENABLED
  );
  const isNewVersion = route.id === 'newVersion';
  const { groupId, assetId } = params;
  const asset = isNewVersion ? assetsSelectors.asset(state, params) : undefined;

  let organization;

  if (isNewVersion) {
    const assetOrganization = profile.contributorOfOrganizations.find(
      (profileOrganization) => profileOrganization.id === asset.organization.id
    );

    organization = assetOrganization || asset.organization;
  } else {
    organization = getActiveOrganization(profile);
  }

  if (!organization) {
    // dummy organization
    organization = {
      id: getActiveOrganizationId(profile)
    };
  }

  return {
    asset,
    assetId,
    assetsLimit,
    context,
    groupId,
    isNewVersion,
    isPublishFormV2Enabled,
    organization,
    profile
  };
};

const mapDispatchToProps = (
  dispatch,
  { increaseProfileAssetsCount, openModal, router: { getPath, push } }
) => ({
  onShowAssetLimitModal: (asset = {}, limit) =>
    openModal(AssetLimitModal.modalType, {
      assetLimit: limit,
      buttonText: asset ? 'Go back to asset portal' : 'Go back to assets list',
      onClose: () => {
        const url = asset.groupId
          ? getPath('assetHome', asset)
          : getPath('home');

        push(url);
      }
    }),
  onCreateSuccess: async (createdAsset) => {
    await dispatch(
      assetsActions.goToCreatedAssetDetail({
        ...createdAsset,
        getPath,
        pushFn: push
      })
    );

    dispatch(sessionActions.increaseProfileAssetsCount());
    increaseProfileAssetsCount();
  },
  onCancel: (asset = {}) => {
    const url = asset.groupId ? getPath('assetHome', asset) : getPath('home');

    push(url);
  },
  onError: (errorHandler) => dispatch(errorHandler)
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onCancel: () => dispatchProps.onCancel(stateProps.asset),
  onError: (error) => {
    const { profile } = stateProps;
    const isRAMLError = (error.details || []).find(
      (detail) => detail.code === 'ASSET_FILE_INVALID_RAML'
    );
    const isOASError = (error.details || []).find(
      (detail) => detail.code === 'ASSET_FILE_INVALID_OAS'
    );
    const hasDesignCenterEntitlement = objectsUtils.getProp(
      profile,
      'organization.entitlements.designCenter.api'
    );

    if (isRAMLError && hasDesignCenterEntitlement) {
      // eslint-disable-next-line no-param-reassign
      error.hint = <PublishErrorHint type="RAML" />;
    } else if (isOASError && hasDesignCenterEntitlement) {
      // eslint-disable-next-line no-param-reassign
      error.hint = <PublishErrorHint type="OAS" />;
    }

    return dispatchProps.onError(() => {
      const isBadRequestError = (err) => err.status === 400;

      if (isBadRequestError(error)) {
        // eslint-disable-next-line no-param-reassign
        error.summary =
          'There was an error publishing the asset because some of the information provided is incorrect.';
        // eslint-disable-next-line no-param-reassign
        error.title = 'Publication Failure';
      }

      // This code should be removed with EXC-7178
      // eslint-disable-next-line no-param-reassign
      error.message = error.message.split('|')[0];

      return Promise.reject(error);
    });
  }
});

const mapRootDispatchToProps = (dispatch) => ({
  increaseProfileAssetsCount: () =>
    dispatch(sessionActions.increaseProfileAssetsCount())
});

export default compose(
  withConfig,
  withModal,
  withRouter,
  withRouterActions,
  connect(null, mapRootDispatchToProps, null, { context: RootStoreContext }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withFocusRestore
)(CreateAsset);
