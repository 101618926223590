import PropTypes from 'prop-types';
import React from 'react';
import EmptyResult from '@mulesoft/anypoint-components/lib/EmptyResult';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import { RouterContext } from '@mulesoft/exchange-react-hooks';
import InfiniteScroll from '~/components/Layout/InfiniteScroll';
import * as objectUtils from '~/utils/objects';
import { getIconURL } from '~/utils/routes';
import styles from './AssetCardList.css';
import AssetCardWrapper from './AssetCardWrapper';

const MULESOFT_CERTIFIED_TAG = 'mulesoft-certified';

class AssetCardList extends React.Component {
  static propTypes = {
    assets: PropTypes.arrayOf(AssetPropType),
    hasMoreAssets: PropTypes.bool,
    isFetchingAssets: PropTypes.bool,
    onAssetClick: PropTypes.func,
    onLoadAssets: PropTypes.func.isRequired,
    onReachEnd: PropTypes.func,
    query: PropTypes.object,
    searchTerm: PropTypes.string
  };

  static contextType = RouterContext;

  static defaultProps = { searchTerm: '' };

  loadMoreAssets = () => {
    const query = {
      offset: this.props.assets.length,
      ...this.props.query
    };

    this.props.onReachEnd();

    return this.props.onLoadAssets(query);
  };

  renderAssetCard = (assetProps, index) => {
    const { onAssetClick } = this.props;
    const key = assetProps.id ?? index;

    return (
      <AssetCardWrapper
        {...assetProps}
        onAssetClick={onAssetClick}
        key={key}
        startExpanded={index === 0}
        resultPosition={index + 1}
      />
    );
  };

  render() {
    const { assets, searchTerm, isFetchingAssets, hasMoreAssets } = this.props;

    if (assets.length === 0 && !isFetchingAssets) {
      return (
        <div role="alert">
          <EmptyResult
            className={styles.emptyResult}
            message="We couldn't find results that match your criteria"
            testId="assets-empty-result"
          />
        </div>
      );
    }

    const assetData = assets.map(({ highlight, ...asset }) => ({
      ...objectUtils.pick(
        asset,
        'groupId',
        'assetId',
        'name',
        'description',
        'minorVersionModifiedAt',
        'minorVersion',
        'numberOfRates',
        'rating',
        'type',
        'organization'
      ),
      icon: getIconURL({
        ...asset,
        isPublicPortal: !!this.context?.context?.publicPortalDomain
      }),
      createdBy: asset.createdBy
        ? `${asset.createdBy.firstName} ${asset.createdBy.lastName}`
        : '-',
      isCertified: (asset.labels ?? []).some(
        (label) => label === MULESOFT_CERTIFIED_TAG
      ),
      searchTerm,
      highlight
    }));

    return (
      <div role="list" data-test-id="asset-card-list">
        <InfiniteScroll
          hasMore={hasMoreAssets}
          onLoadMore={this.loadMoreAssets}
          loadingMoreMessage="Loading more assets ..."
          loadMoreMessage="Load more assets"
          testId="assets"
          renderItem={this.renderAssetCard}
          items={assetData}
        />
      </div>
    );
  }
}

export default AssetCardList;
