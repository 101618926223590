import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import loadable from '@loadable/component';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import Table from '@mulesoft/exchange-ui-components/lib/components/Table';
import { SkipLinks } from '@mulesoft/exchange-ui-components';
import Search from '~/components/Home/Search/SearchBox';
import InfiniteScroll from '~/components/Layout/InfiniteScroll';
import ApplicationName from './ApplicationName';
import styles from './ClientApplicationList.css';

const headers = [
  { key: 'name', label: 'Name' },
  { key: 'description', label: 'Description' }
];

const ApplicationDetails = loadable(
  () =>
    import(
      /* webpackChunkName: "client-applications" */ '@mulesoft/client-applications-ui/raw'
    ),
  { ssr: false }
);

ApplicationDetails.displayName = 'ApplicationDetails';

class ClientApplicationList extends PureComponent {
  static propTypes = {
    applications: PropTypes.arrayOf(PropTypes.object),
    isEditMode: PropTypes.bool,
    isFetchingList: PropTypes.bool,
    organization: PropTypes.object,
    hasMoreApplications: PropTypes.bool,
    query: PropTypes.object,
    onApplicationClicked: PropTypes.func,
    onListLoaded: PropTypes.func,
    onLoadMoreApplications: PropTypes.func,
    onReachEnd: PropTypes.func,
    onSearchApplications: PropTypes.func
  };

  componentDidMount() {
    const { onListLoaded } = this.props;

    ApplicationDetails.preload();

    if (!this.props.isFetchingList) {
      onListLoaded(this.getAnalyticsPayload());
    }
  }

  componentDidUpdate(prevProps) {
    const { applications: prevApplications, query: prevQuery } = prevProps;
    const { applications, query, isFetchingList } = this.props;

    if (
      !isFetchingList &&
      (prevApplications.length !== applications.length ||
        query.search !== prevQuery.search)
    ) {
      const { onListLoaded } = this.props;

      onListLoaded(this.getAnalyticsPayload());
    }
  }

  getAnalyticsPayload() {
    const { applications, query } = this.props;
    const { search = '' } = query;

    return {
      applicationCount: applications.length,
      queryString: search,
      queryStringLength: search.length
    };
  }

  renderApplications() {
    const { applications, organization, onApplicationClicked } = this.props;

    return applications.map((application, i) => ({
      name: (
        <ApplicationName
          testId={`application-${i}`}
          key={`application-${i}`}
          application={application}
          organization={organization}
          onClick={() =>
            onApplicationClicked({
              ...this.getAnalyticsPayload(),
              position: i
            })
          }
        />
      ),
      description: application.description
    }));
  }

  loadMoreApplications = () => {
    const query = {
      offset: this.props.applications.length,
      search: this.props.query.search
    };

    this.props.onReachEnd(this.getAnalyticsPayload());

    return this.props.onLoadMoreApplications(this.props.organization.id, query);
  };

  renderSpinner = () => (
    <div className={styles.spinner}>
      <Spinner size="l" />
    </div>
  );

  renderApplicationList = () => {
    const { hasMoreApplications } = this.props;

    return (
      <InfiniteScroll
        hasMore={hasMoreApplications}
        onLoadMore={this.loadMoreApplications}
        loadMoreMessage="Loading more applications"
        testId="applications"
        ariaLive="assertive"
      >
        <Table
          caption="Client applications"
          testId="applications-table"
          className={styles.table}
          headers={headers}
          rows={this.renderApplications()}
          emptyMessage="You have no client applications."
        />
      </InfiniteScroll>
    );
  };

  render() {
    const { isEditMode, isFetchingList, query, onSearchApplications } =
      this.props;

    return (
      <section
        className={classNames(styles.container, isEditMode && styles.editMode)}
        aria-labelledby="applications-heading"
      >
        <SkipLinks />
        <h1 id="applications-heading">My applications</h1>
        <div className={styles.searchBar}>
          <Search
            id="my-applications-search"
            ariaLabelledby="applications-heading"
            query={query}
            onSearch={onSearchApplications}
            theme="grey"
          />
        </div>
        {isFetchingList ? this.renderSpinner() : this.renderApplicationList()}
      </section>
    );
  }
}

export default ClientApplicationList;
